<template lang="pug">
    nav#mainMenu(:class="{open: open, home: $route.name == 'home' }")
        ul
            li(@click="toggleMenu()")
                router-link(:to="{name : 'home', hash: '#galerias'}") Sobre
            li(@click="toggleMenu()")
                a(@click="anchorTo('#yachthouse', 'empresas')" v-if="$route.name == 'home'") GT Business
                router-link(:to="{name : 'home', hash: '#yachthouse', params: {option: 'empresas'}}" v-else) GT Business
            //- li(@click="toggleMenu()")
                router-link(:to="{name : 'home', hash: '#executivos'}") Executivos
            li(@click="toggleMenu()")
                router-link(:to="{name : 'blog'}") Blog
            li(@click="toggleMenu()")
                router-link(:to="{name : 'imprensa'}") Imprensa
            li(@click="toggleMenu()")
                router-link(:to="{name : 'home', hash: '#contato'}") Contato

        button(type="button", @click="toggleMenu()", :class="{ white }").toggle
            include ../../assets/svgs/icon_close.svg

        router-link(:to="{name : 'blog'}" v-if="$route.name == 'imprensa'").shortcut Blog
        router-link(:to="{name : 'imprensa'}" v-if="$route.name == 'blog'").shortcut Imprensa
</template>

<script>
export default {
    data() {
        return {
            open: false,
            white: false,
        }
    },
    created() {
        window.addEventListener('scroll', () => {
            if (this.$route.name == 'imprensa' || this.$route.name == 'blog') {
                if (window.scrollY >= 950)
                    this.white = true
                else
                    this.white = false
            }
        })
    },
    methods: {
        toggleMenu() {
            this.open = !this.open
        },
        anchorTo(to, option = null) {
            this.$scrollTo(to, () => {
                if(option != null)
                    this.$openGaleria(option)
            })
        }
    },
}
</script>

<style lang="stylus" scoped src="./Menu.styl"></style>
