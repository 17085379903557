<template lang="pug">
    footer#mainFooter
        div.wrapper
            small 2019 Copyright - Todos os direitos reservados.
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
