import Vue from 'vue'
import Devinho from '@/libs/devinho'

Vue.use(Devinho);

Vue.prototype.$scrollTo = function (id, callback = null) {
	Vue.devinho.getElement(id).scrollIntoView({
		behavior: 'smooth'
	});

	if (callback)
		callback();
}

Vue.prototype.$openGaleria = function (option) {
	if (option == 'empresas') {
		Vue.devinho.addClass("#empresas", 'ativo')

		// Vue.devinho.removeClass("#galerias .gtpartners", 'ativo')
		// Vue.devinho.addClass("#galerias .gtbusiness", 'ativo')

		Vue.devinho.removeClass("#letterings #nossoDNA", "toLeft")
		setTimeout(() => {
			Vue.devinho.addClass("#letterings #negociosInteligentes", "toLeft")
		}, 1000)
	} else {
		Vue.devinho.removeClass("#empresas", 'ativo')
		// Vue.devinho.removeClass("#partners", 'ativo')
		// Vue.devinho.removeClass("#galerias .gtbusiness", 'ativo')
		// Vue.devinho.removeClass("#galerias .gtpartners", 'ativo')
		Vue.devinho.removeClass("#letterings #negociosInteligentes", "toLeft")
		Vue.devinho.removeClass("#letterings #nossoDNA", "toLeft")
	}
}

Vue.prototype.$toggleGaleria = function (option) {
	if (option == 'empresas') {
		Vue.devinho.toggleClass("#empresas", 'ativo')
	}
}
