window.onload = function() {
	setTimeout (function () {
		scrollTo(0,0);
	}, 1);
}

import './assets/stylus/main.styl'

import Vue from 'vue'
import Axios from 'axios';
// import VueScrollMagic from 'vue-scrollmagic'
import App from './App.vue'
import router from './router/desktop'
import Devinho from '@/libs/devinho'
import '@/libs/gtcompany'

Vue.config.productionTip = false

const base_tag = document.querySelector('meta[name="base"]')
Vue.prototype.$axios = Axios.create({
	baseURL: `${base_tag ? base_tag.getAttribute('content') : ''}/api`
})

Vue.use(Devinho);
// Vue.use(VueScrollMagic, {
// 	// addIndicators: true
// });

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
