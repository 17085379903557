<template lang="pug">
	main#app
		Menu(v-if="$route.name != 'card-funcionario'")
		router-view(:key="$route.name")
		Footer(v-if="$route.name != 'card-funcionario'")
		Descubra(v-if="$route.name != 'card-funcionario'")
		Letterings(v-if="$route.name != 'card-funcionario'")
</template>

<script>
import Footer from '@/components/footer/Footer.vue'
import Descubra from '@/components/Descubra/Descubra.vue'
import Letterings from '@/components/Letterings/Letterings.vue'
import Menu from '@/components/Menu/Menu.vue'

export default {
	components: {
		Footer,
		Descubra,
		Letterings,
		Menu
	}
};
</script>

<style lang="stylus" scoped src="./assets/stylus/App.styl"></style>
