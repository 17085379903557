import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const scrollBehavior = (to, from, savedPosition) => { // eslint-disable-line
    return new Promise((resolve) => { // eslint-disable-line
        if (to.hash) {
            setTimeout(() => {
                return resolve({
                    selector: to.hash,
                    behavior: 'smooth',
                })
            }, 500)
        } else {
            setTimeout(() => {
                return resolve({ x: 0, y: 0 })
            }, 500)
        }
    })
}

export default new Router({
	mode: 'history',
	base: process.env.VUE_APP_PUBLICPATH,
	// eslint-disable-next-line
	scrollBehavior,
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import( /* webpackChunkName: "home" */ '@/views/Home/Home.vue')
		},
		{
			path: '/blog',
			name: 'blog',
			component: () => import( /* webpackChunkName: "posts" */ '@/views/Posts/Posts.vue')
		},
		{
			path: '/blog/:slug',
			name: 'post-blog',
			component: () => import( /* webpackChunkName: "post" */ '@/views/Post/Post.vue')
		},
		{
			path: '/imprensa',
			name: 'imprensa',
			component: () => import( /* webpackChunkName: "posts" */ '@/views/Posts/Posts.vue')
		},
		{
			path: '/imprensa/:slug',
			name: 'post-imprensa',
			component: () => import( /* webpackChunkName: "post" */ '@/views/Post/Post.vue')
		},
		{
			path: '/funcionario/:slug',
			name: 'card-funcionario',
			component: () => import( /* webpackChunkName: "cardFuncionario" */ '@/views/CardFuncionario/CardFuncionario.vue')
		},
	]
})
