class Devinho {
	constructor() {
		this.functions = {
			getElement: (identifier) => document.querySelector(identifier),
			getComputed: (element, property) => {
				if (typeof element === 'string')
					element = this.functions.getElement(element);
				return window.getComputedStyle(element)[property]
			},
			getHeight: (element) => parseFloat(this.functions.getComputed(element, 'height')),
			getClientHeight: (element) => parseFloat(this.functions.getComputed(element, 'clientHeight')),
			getWidth: (element) => parseFloat(this.functions.getComputed(element, 'width')),
			getMargin: (element, side = 'right') => parseFloat(this.functions.getComputed(element, `margin-${side}`)),
			addClass: (element, classe) => this.functions.getElement(element).classList.add(classe),
			removeClass: (element, classe) => this.functions.getElement(element).classList.remove(classe),
			toggleClass: (element, classe) => this.functions.getElement(element).classList.toggle(classe),
		}
	}

	// eslint-disable-next-line
	install(Vue, options) {
		Vue.devinho = {};
		Vue.prototype.$devinho = {};
		Object.keys(this.functions).forEach(functionName => {
			Vue.devinho[functionName] = this.functions[functionName];
			Vue.prototype.$devinho[functionName] = this.functions[functionName];
		})
	}
}

export default new Devinho();
