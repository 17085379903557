<template lang="pug">
    aside#descubra
        div.business(@click="anchorTo('#yachthouse', 'empresas')")
            figure.logo
                img(:src="require('@/assets/images/logo/logo_gtcompany_business.png')", alt="GT Company - Business")
        div.partners(@click="anchorTo('#yatchhouse', 'empresas')")
            figure.logo
                img(:src="require('@/assets/images/logo/logo_gtcompany_partners.png')", alt="GT Company - Partners")
</template>

<script>
export default {
    methods: {
        anchorTo(to, option) {
            this.$scrollTo('#galerias', () => {
                this.$openGaleria(option)
            })
        }
    },
}
</script>

<style lang="stylus" scoped src="./Descubra.styl"></style>