<template lang="pug">
    aside#letterings
        include ../../assets/svgs/letterings/nossosBensMaisValiosos.svg
        include ../../assets/svgs/letterings/aInteligenciaDoProposito.svg
        include ../../assets/svgs/letterings/deUmaProfissaoDeUmLegado.svg
        // include ../../assets/svgs/letterings/tudoTemUmProposito.svg
        include ../../assets/svgs/letterings/negociosInteligentes.svg
        include ../../assets/svgs/letterings/nossoDNA.svg
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped src="./Letterings.styl"></style>